<template>
  <!--
    Used to edit the theme-elements that are used in the videoPostprocessing.
  -->
  <Portlet
    :title="portletTilte"
    icon="tv"
    class="layoutTextEdit"
  >
    <template
      slot="buttons"
    >
      <toggle-button
        v-model="previewOtherElements"
        :labels="{ checked: 'show other', unchecked: 'hide other' }"
        class="ml-3 mt-2"
        :width="120"
        :height="30"
        :font-size="12"
      />
    </template>
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <template v-if="layoutTextElement && themeId && elementId">
        <div
          class="el-table_1_column_1 is-left mb-3"
          colspan="2"
        >
          <div>
            <div class="cell row">
              <div class="col-sm-12 col-lg-8">
                <Preview
                  :element="layoutTextElement"
                  :edit="true"
                  :theme-id="themeId"
                  :preview-other-elements="previewOtherElements"
                  :parent-errors="$validator.errors.items"
                  tag="rect"
                  @changeAttribute="changeAttributeNow"
                />
              </div>
              <div class="col-sm-12 col-lg-4">
                <h4 class="m-0">
                  {{ $t('layoutTextEditComp.layoutTextElements') }}
                </h4>
                <hr class="m-0 mb-3">

                <div
                  class="form-group row mb-4"
                >
                  <label class="col-12 col-md-2">{{ $t('text') }}</label>
                  <div class="col-12 col-md-10">
                    <input
                      v-model="layoutTextElement.text"
                      v-focus
                      :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('Text') }]"
                      type="text"
                    >
                    <span
                      v-show="errors.has('Text')"
                      class="badge badge-danger"
                    >{{ errors.first('Text') }}</span>
                  </div>
                </div>

                <div
                  class="form-group row mb-4"
                >
                  <label class="col-12 col-md-2">{{ $t('color') }}</label>
                  <div class="col-12 col-md-10">
                    <input
                      v-model="layoutTextElement.color"
                      :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('Color') }]"
                      type="text"
                    >
                    <span
                      v-show="errors.has('Color')"
                      class="badge badge-danger"
                    >{{ errors.first('Color') }}</span>
                  </div>
                </div>

                <div
                  class="form-group row mb-4"
                >
                  <label class="col-12 col-md-2">{{ $t('fontFamily') }}</label>
                  <div class="col-12 col-md-10">
                    <select
                      v-model="layoutTextElement.fontId"
                      :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('FontFamily') }]"
                    >
                      <option :value="0">
                        {{ $t('pleaseSelectOne') }}
                      </option>
                      <option
                        v-for="(fontFamily, fontFamilyIndex) in fontFamilies"
                        :key="`themeElementEditFontFamilyOption-${ fontFamilyIndex }`"
                        :value="fontFamily.id"
                      >
                        {{ fontFamily.name }}
                      </option>
                    </select>
                    <span
                      v-show="errors.has('FontFamily')"
                      class="badge badge-danger"
                    >{{ errors.first('FontFamily') }}</span>
                  </div>
                </div>

                <div
                  class="form-group row mb-4"
                >
                  <label class="col-12 col-md-2">{{ $t('fontSize') }}</label>
                  <div class="col-12 col-md-10">
                    <input
                      v-model="layoutTextElement.fontSize"
                      :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('FontSize') }]"
                      type="text"
                    >
                    <span
                      v-show="errors.has('FontSize')"
                      class="badge badge-danger"
                    >{{ errors.first('FontSize') }}</span>
                  </div>
                </div>

                <div
                  class="form-group row mb-4"
                >
                  <label class="col-12 col-md-2">{{ $t('fontWeight') }}</label>
                  <div class="col-12 col-md-10">
                    <input
                      v-model="layoutTextElement.fontWeight"
                      :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('FontWeight') }]"
                      type="text"
                    >
                    <span
                      v-show="errors.has('FontWeight')"
                      class="badge badge-danger"
                    >{{ errors.first('FontWeight') }}</span>
                  </div>
                </div>

                <div
                  class="form-group row mb-4"
                >
                  <label class="col-12 col-md-2">{{ $t('textAlign') }}</label>
                  <div class="col-12 col-md-10">
                    <select
                      v-model="layoutTextElement.textAlign"
                      :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('TextAlign') }]"
                    >
                      <option :value="null">
                        {{ $t('pleaseSelectOne') }}
                      </option>
                      <option
                        v-for="(textAlign, textAlignIndex) in textAligns"
                        :key="`themeElementEditTextAlignOption-${ textAlignIndex }`"
                        :value="textAlign"
                      >
                        {{ textAlign }}
                      </option>
                    </select>
                    <span
                      v-show="errors.has('TextAlign')"
                      class="badge badge-danger"
                    >{{ errors.first('TextAlign') }}</span>
                  </div>
                </div>

                <div
                  class="form-group row mb-4"
                >
                  <label class="col-12 col-md-2">{{ $t('textTransform') }}</label>
                  <div class="col-12 col-md-10">
                    <select
                      v-model="layoutTextElement.textTransform"
                      :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('TextTransform') }]"
                    >
                      <option
                        value="none"
                      >
                        {{ $t('none') }}
                      </option>
                      <option value="uppercase">
                        {{ $t('uppercase') }}
                      </option>
                      <option value="lowercase">
                        {{ $t('lowercase') }}
                      </option>
                    </select>
                    <span
                      v-show="errors.has('TextTransform')"
                      class="badge badge-danger"
                    >{{ errors.first('TextTransform') }}</span>
                  </div>
                </div>
                <div
                  class="form-group row mb-4"
                >
                  <label class="col-12 col-md-2">Sort Number</label>
                  <div class="col-12 col-md-10">
                    <input
                      v-model="layoutTextElement.renderSortNumber"
                      :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('RenderSortNumber') }]"
                      type="number"
                    >
                    <span
                      v-show="errors.has('RenderSortNumber')"
                      class="badge badge-danger"
                    >{{ errors.first('RenderSortNumber') }}</span>
                  </div>
                </div>

                <template>
                  <h4 class="m-0 mt-4">
                    {{ $t('start') }}
                  </h4>
                  <hr class="m-0 mb-3">
                </template>
                
                <div
                  class="form-group row mb-4"
                >
                  <label class="col-12 col-md-2">{{ $t('themeElementEdit.eventKey') }}</label>
                  <div class="col-12 col-md-10">
                    <select
                      v-model="layoutTextElement.startEventKey"
                      :class="['form-control', { 'is-invalid': this.$validator.errors.has('StartEventKey') }]"
                    >
                      <option
                        v-for="(key, index) in startModifiedEventKeys"
                        :key="`cutConfigListItemAdd_Option${ index }`"
                        :value="key"
                      >
                        {{ key }}
                      </option>
                    </select>
                    <span
                      v-show="errors.has('StartEventKey')"
                      class="badge badge-danger"
                    >{{ errors.first('StartEventKey') }}</span>
                  </div>
                </div>

                <div
                  class="form-group row mb-4"
                >
                  <label class="col-12 col-md-2">{{ $t('themeElementEdit.eventReporter') }}</label>
                  <div class="col-12 col-md-10">
                    <select
                      v-model="layoutTextElement.startEventReporter"
                      :class="['form-control', { 'is-invalid': this.$validator.errors.has('StartEventReporter') }]"
                      @change="setBackStartEventReporterIndex(); setStartEventKeyListValues($event)"
                    >
                      <option
                        v-for="(key, index) in eventReporters"
                        :key="`cutConfigListItemAdd_Option2${ index }`"
                        :value="key"
                      >
                        {{ key }}
                      </option>
                    </select>
                    <span
                      v-show="errors.has('StartEventReporter')"
                      class="badge badge-danger"
                    >{{ errors.first('StartEventReporter') }}</span>
                  </div>
                </div>
                <div
                  v-if="layoutTextElement.startEventReporter == 'VideoRecorder'"
                  class="form-group row mb-4"
                >
                  <label class="col-12 col-md-2">{{ $t('themeElementEdit.eventReporterIndex') }} </label>
                  <div class="col-12 col-md-10">
                    <input
                      v-model="layoutTextElement.startEventReporterIndex"
                      :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('StartEventIndex') }]"
                      type="number"
                    >
                    <span
                      v-show="errors.has('StartEventIndex')"
                      class="badge badge-danger"
                    >{{ errors.first('StartEventIndex') }}</span>
                  </div>
                </div>

                <div
                  class="form-group row mb-4"
                >
                  <label class="col-12 col-md-2">{{ $t('themeElementEdit.offset') }} <small>({{ $t('seconds') }})</small></label>
                  <div class="col-12 col-md-10">
                    <input
                      v-model="layoutTextElement.startOffset"
                      :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('StartOffset') }]"
                      type="number"
                    >
                    <span
                      v-show="errors.has('StartOffset')"
                      class="badge badge-danger"
                    >{{ errors.first('StartOffset') }}</span>
                  </div>
                </div>

                <template>
                  <h4 class="m-0 mt-4">
                    {{ $t('end') }}
                  </h4>
                  <hr class="m-0 mb-3">
                </template>

                <div
                  class="form-group row mb-4"
                >
                  <label class="col-12 col-md-2">{{ $t('themeElementEdit.eventKey') }}</label>
                  <div class="col-12 col-md-10">
                    <select
                      v-model="layoutTextElement.endEventKey"
                      :class="['form-control', { 'is-invalid': this.$validator.errors.has('EndEventKey') }]"
                    >
                      <option
                        v-for="(key, index) in endModifiedEventKeys"
                        :key="`cutConfigListItemAdd_Option2${ index }`"
                        :value="key"
                      >
                        {{ key }}
                      </option>
                    </select>
                    <span
                      v-show="errors.has('EndEventKey')"
                      class="badge badge-danger"
                    >{{ errors.first('EndEventKey') }}</span>
                  </div>
                </div>

                <div
                  class="form-group row mb-4"
                >
                  <label class="col-12 col-md-2">{{ $t('themeElementEdit.eventReporter') }}</label>
                  <div class="col-12 col-md-10">
                    <select
                      v-model="layoutTextElement.endEventReporter"
                      :class="['form-control', { 'is-invalid': this.$validator.errors.has('EndEventReporter') }]"
                      @change="setBackEndEventReporterIndex(); setEndEventKeyListValues($event)"
                    >
                      <option
                        v-for="(key, index) in eventReporters"
                        :key="`cutConfigListItemAdd_Option2${ index }`"
                        :value="key"
                      >
                        {{ key }}
                      </option>
                    </select>
                    <span
                      v-show="errors.has('EndEventReporter')"
                      class="badge badge-danger"
                    >{{ errors.first('EndEventReporter') }}</span>
                  </div>
                </div>
                <div
                  v-if="layoutTextElement.endEventReporter == 'VideoRecorder'"
                  class="form-group row mb-4"
                >
                  <label class="col-12 col-md-2">{{ $t('themeElementEdit.eventReporterIndex') }} </label>
                  <div class="col-12 col-md-10">
                    <input
                      v-model="layoutTextElement.endEventReporterIndex"
                      :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('EndEventIndex') }]"
                      type="number"
                    >
                    <span
                      v-show="errors.has('EndEventIndex')"
                      class="badge badge-danger"
                    >{{ errors.first('EndEventIndex') }}</span>
                  </div>
                </div>

                <div
                  class="form-group row mb-4"
                >
                  <label class="col-12 col-md-2">{{ $t('offset') }} <small>({{ $t('seconds') }})</small></label>
                  <div class="col-12 col-md-10">
                    <input
                      v-model="layoutTextElement.endOffset"
                      :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('EndOffset') }]"
                      type="number"
                    >
                    <span
                      v-show="errors.has('EndOffset')"
                      class="badge badge-danger"
                    >{{ errors.first('EndOffset') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          class="btn btn-sm btn-secondary float-left"
          @click="cancel()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="times"
          />{{ $t('cancel') }}
        </button>
        <button
          class="btn btn-sm btn-primary float-right"
          @click="update()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="check"
          />{{ $t('save') }}
        </button>
        <div class="clearfix" />
      </template>
      <template v-else>
        {{ $t('noDataAvailable') }}
      </template>
    </template>
  </Portlet>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "LayoutTextEdit",
  components: {
    Preview: () => import('@/components/VideoPostprocessing/Preview.vue')
  },
  mixins: [
    errorMixin
  ],
  props: {
    themeId: {
      type: String,
      required: true
    },
    elementId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      layoutTextElement: null,
      eventKeys: null,
      eventReporters: null,
      fontFamilies: null,
      previewOtherElements: true,
      textAligns: [
        "left",
        "center",
        "right",
        "justified"
      ],
      startFinishEventKeys: [
        'SessionStarted', 
        'SessionFinished', 
        'RaceStarted', 
        'RaceFinished', 
        'RaceAborted'
      ],
      startModifiedEventKeys: null,
      endModifiedEventKeys: null
    }
  },
  computed: {
    portletTilte () {
      if (this.theme == null) {
        return this.$t('themeElementEdit.themeElementEdit');
      }
      if (this.theme.name == null) {
        return this.$t('themeElementEdit.themeElementEdit');
      }
      return `${ this.$t('themeElementEdit.themeElementEdit') }: ${ this.theme.name }`;
    }
  },
  created () {
    this.getDataWithLoading();
  },
  methods: {
    cancel () {
      this.$router.push(`/videopostprocessing/theme/${ this.themeId }/layoutimageelement`);
    },
    getData () {
      this.axios.all([
        this.axios.get(`/VideoPostProcessing/GetLayoutTextElement?layoutTextElementId=${ this.elementId }`),
        this.axios.get(`/VideoPostprocessing/GetEventKeys`),
        this.axios.get(`/VideoPostprocessing/GetEventReporters`),
        this.axios.get(`/VideoPostProcessing/GetAllFontElements`)    
      ])
        .then(this.axios.spread((layoutTextElementResponse, keyResponse, reportResponse, fontResponse) => {
          if (layoutTextElementResponse == null) {
            return;
          }
          if (layoutTextElementResponse.data == null) {
            return;
          }
          this.layoutTextElement = layoutTextElementResponse.data;
          if (keyResponse == null) {
            return;
          }
          if (keyResponse.data == null) {
            return;
          }
          this.eventKeys = keyResponse.data;

          if (reportResponse == null) {
            return;
          }
          if (reportResponse.data == null) {
            return;
          }
          this.eventReporters = reportResponse.data;
          if (fontResponse == null) {
            return;
          }
          if (fontResponse.data == null) {
            return;
          }
          this.fontFamilies = fontResponse.data;

          var startEventReporterVal = this.layoutTextElement.startEventReporter;
          var endEventReporterVal = this.layoutTextElement.endEventReporter;

          if (startEventReporterVal === "StartFinish") {
            this.startModifiedEventKeys = this.eventKeys.filter(x => this.startFinishEventKeys.includes(x) && x != "Unknown");
          } else if (startEventReporterVal === "VideoRecorder") {
            this.startModifiedEventKeys = this.eventKeys.filter(x => !this.startFinishEventKeys.includes(x) && x != "Unknown");
          } else {
            this.startModifiedEventKeys = this.eventKeys.filter(x => x == "Unknown");
            this.startEventKey = "Unknown";
          }

          if (!this.startModifiedEventKeys.includes(this.layoutTextElement.startEventKey)) {
            this.layoutTextElement.startEventKey = this.startModifiedEventKeys.find(x => x);
          }

          if (endEventReporterVal === "StartFinish") {
            this.endModifiedEventKeys = this.eventKeys.filter(x => this.startFinishEventKeys.includes(x) && x != "Unknown");
          } else if (endEventReporterVal === "VideoRecorder") {
            this.endModifiedEventKeys = this.eventKeys.filter(x => !this.startFinishEventKeys.includes(x) && x != "Unknown");
          } else {
            this.endModifiedEventKeys = this.eventKeys.filter(x => x == "Unknown");
            this.endEventKey = "Unknown";
          }

          if (!this.endModifiedEventKeys.includes(this.layoutTextElement.endEventKey)) {
            this.layoutTextElement.endEventKey = this.endModifiedEventKeys.find(x => x);
          }

        }))
        .finally(() => {
          this.loading = false;
        });
    },
    getDataWithLoading () {
      this.loading = true;
      this.getData();
    },
    update () {
      this.axios.put(`/VideoPostProcessing/UpdateLayoutTextElement`, this.layoutTextElement)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.status == null) {
            return;
          }
          if (response.status != 200) {
            return;
          }
          this.error_clear();
          this.$snotify.success(this.$t('layoutTextEditComp.updateSuccess'));
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
          this.loading = false;
        });
    },
    deleteElement () {
      this.axios.delete(`/VideoPostProcessing/DeleteLayoutTextElement?layoutTextElementId=${ this.elementId }`)
        .then(() => {
          this.$snotify.success(this.$t('themeElementEdit.themeDeleteSuccess'));
          this.$router.push({path: `/videopostprocessing/theme/${ this.themeId }` });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    changeAttributeNow (value, attribute) {
      this.layoutTextElement[attribute] = value;
    },
    setBackStartEventReporterIndex () {
      if(this.layoutTextElement.startEventReporter != 'VideoRecorder')
      {
        this.layoutTextElement.startEventReporterIndex = '0';
      }
      else if(this.layoutTextElement.startEventReporterIndex === undefined)
      {
        this.layoutTextElement.startEventReporterIndex = '1';
      }
    },
    setBackEndEventReporterIndex () {
      if(this.layoutTextElement.endEventReporter != 'VideoRecorder')
      {
        this.layoutTextElement.endEventReporterIndex = '0';
      }
      else if(this.layoutTextElement.endEventReporterIndex === undefined)
      {
        this.layoutTextElement.endEventReporterIndex = '1';
      }
    },
    setStartEventKeyListValues (event) {
      var val = event.target.value;

      if (val === "StartFinish") {
          this.startModifiedEventKeys = this.eventKeys.filter(x => this.startFinishEventKeys.includes(x) && x != "Unknown");
      } else if (val === "VideoRecorder") {
          this.startModifiedEventKeys = this.eventKeys.filter(x => !this.startFinishEventKeys.includes(x) && x != "Unknown");
      } else {
          this.startModifiedEventKeys = this.eventKeys.filter(x => x == "Unknown");
      }

      this.layoutTextElement.startEventKey = this.startModifiedEventKeys.find(x => x);
    },
    setEndEventKeyListValues (event) {
      var val = event.target.value;

      if (val === "StartFinish") {
          this.endModifiedEventKeys = this.eventKeys.filter(x => this.startFinishEventKeys.includes(x) && x != "Unknown");
      } else if (val === "VideoRecorder") {
          this.endModifiedEventKeys = this.eventKeys.filter(x => !this.startFinishEventKeys.includes(x) && x != "Unknown");
      } else {
          this.endModifiedEventKeys = this.eventKeys.filter(x => x == "Unknown");
      }

      this.layoutTextElement.endEventKey = this.endModifiedEventKeys.find(x => x);
    }
  }
}
</script>
