export const errorMixin = {
  methods: {
    error_validate (error) {
      if (error.response.status == 422 && typeof error.response.data == "object" && error.response.data) {
        error.response.data.forEach(error1 => { 
        this.$validator.errors.add({
            id: error1.key,
            field: error1.key,
            msg: error1.errorMessage[0],
            scope: this.$options.scope,
          });
        });
      }
    },
    error_clear () {
      this.$validator.errors.clear();
    }
  }
}